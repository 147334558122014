import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { breakpoints, spacing } from '../utils/constants'
import SEO from '../components/Seo'
import Video from '../components/Video'
import WhatVideo from '../assets/videos/what.mp4'
import SectionTitle from '../components/SectionTitle'

const AboutParadigm = styled.section`
  max-width: 796px;
  margin: 0 auto;
  padding: 0 ${spacing.sm};
`

const CreatorsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${spacing.sm};
`

const Creator = styled.div`
  width: 50%;
  padding: 0 ${spacing.sm};

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`

const CretorName = styled.h3`
  text-align: center;
`

const CreatorDescription = styled.p`
  font-size: 1rem;
`

const What = ({ data }) => {
  const page = data.file.childMarkdownRemark

  return (
    <Layout>
      <SEO title="What" />
      <Video Src={WhatVideo} />
      <AboutParadigm
        dangerouslySetInnerHTML={{ __html: page.html }}
      ></AboutParadigm>
      <SectionTitle title="The Creators" />
      <CreatorsContainer>
        <Creator>
          <CretorName>{page.frontmatter.paradigm_title}</CretorName>
          <CreatorDescription>
            {page.frontmatter.paradigm_description}
          </CreatorDescription>
        </Creator>
        <Creator>
          <CretorName>{page.frontmatter.discourse_title}</CretorName>
          <CreatorDescription>
            {page.frontmatter.discourse_description}
          </CreatorDescription>
        </Creator>
      </CreatorsContainer>
    </Layout>
  )
}

export const query = graphql`
  query whatPageQuery {
    file(name: { eq: "what" }) {
      childMarkdownRemark {
        id
        frontmatter {
          title
          paradigm_title
          paradigm_description
          discourse_title
          discourse_description
        }
        html
      }
    }
  }
`

export default What
